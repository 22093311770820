// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-js": () => import("../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-page-js": () => import("../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-regions-js": () => import("../src/templates/regions.js" /* webpackChunkName: "component---src-templates-regions-js" */),
  "component---src-templates-event-js": () => import("../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-article-js": () => import("../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

